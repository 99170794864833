








export default {
	name: "LoadingBar",
	props: ['file'],
	data: function() {
		return {
			/**
			* metMinimumDisplayTime is used for showing the progress bar for a minimum amount of time. This provides
			* a more consistent experience between files and helps the visitor know that the file was uploaded
			* successfully.
			*/
			metMinimumDisplayTime: false,
			metMinimumDisplayTimeTimeout: undefined,
		}
	},
	watch: {
		percentage: function (newPercent, oldPercent) {
			if (newPercent === 100) {
				this.metMinimumDisplayTimeTimeout = setTimeout(() => {
					this.metMinimumDisplayTime = true;
				}, 750);
			}
		}
	},
	beforeDestroy() {
		if (this.metMinimumDisplayTimeTimeout) {
			clearTimeout(this.metMinimumDisplayTimeTimeout)
		}
	},
	computed: {
		percentage: function() {
			return this.file.percent;
		}
	}
}
